<template>
  <div>
    <el-form :model="formData" :rules="rules" label-position="right" label-width="96px" ref="menuForm">
      <el-form-item label="提报日期" prop="report_time">
        <el-date-picker
            format="yyyy 年 MM 月 dd 日"
            placeholder="请选择提报日期"
            type="date"
            v-model="formData.report_time"
            value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="项目名称" prop="name">
        <el-input v-model="formData.name"></el-input>
      </el-form-item>
      <el-form-item label="合作品牌" prop="brand">
        <el-input v-model="formData.brand"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" v-model="formData.remark"></el-input>
      </el-form-item>
    </el-form>
    <span class="dialog-footer" slot="footer">
<!--    <el-button @click="dialogClose">取 消</el-button>-->
      <!--                <el-button @click="handleConfirm" type="primary">确认并导出</el-button>-->
  </span>
  </div>

</template>

<script>
import ModalDialog from '@/components/ModalDialog'
import FileSaver from 'file-saver'

export default {
  name: 'Dialog',
  components: {
    ModalDialog, FileSaver
  },
  inject: ['platformCode'],

  data() {
    return {
      loadingStaus: true,
      dialogData: {
        visible: true,
        title: '添加提报',
        width: '30%',
        loading: false,
        modal: false
      }
      , formData: {
        report_time: '',
        name: '',
        brand: '',
        remark: '',
        details: [],
        platform_code: 'douyin'
      }
      , rules: {
        report_time: { required: true, message: '请输入提报日期', trigger: 'blur' },
        name: { required: true, message: '请输入项目名称', trigger: 'blur' },
        brand: { required: true, message: '请输入合作品牌', trigger: 'blur' }
      },
      isSuccess: ''
    }

  },
  props: {
    idsProp: {
      type: [Array],
      default() {
        return []
      }
    }
  },
  // watch: {
  //   submit: {
  //     immediate: true,
  //     handler(n) {
  //       if (n) {
  //         this.handleConfirm()
  //       }
  //
  //     }
  //   }
  // },
  mounted() {
  },
  methods: {
    // 处理模态框，关闭事件
    dialogClose() {
      this.$emit('update:dialogVisible', false)
    },
    // 处理模态框，确定事件
    handleConfirm() {
      // 调用组件的数据验证方法
      this.$refs['menuForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },
    // form数据提交，请求接口
    formSubmit() {
      let newdetails = []
      this.idsProp.forEach((item) => {
        newdetails.push({ kol_platform_id: item.id, kol_id: item.kol_id })
      })
      this.formData.details = newdetails
      this.formData.platform_code = this.platformCode
      // let ids = this.$refs['treeTable'].getExpandIds()
      this.$api.saveKolProject(this.formData).then(async (data) => {
        if (data) {
          let newData = { project_id: data, type: this.platformCode }
          var isFileSaverSupported = !!new Blob
          if (isFileSaverSupported) {
            // let searchCondition = this.handleSearchCondition()
            let response = await this.$api.downloadKolProject(newData)
            let datas = response.data
            let contentType = response.headers['content-type']
            let name = `${this.formData.name}_${this.formData.brand}_${this.formData.report_time}`
            let blob = new Blob([datas], {
              type: contentType
            })
            FileSaver.saveAs(blob, decodeURI(name + '.xlsx'), { autoBom: true })
            this.dialogData.visible = false
            this.$message.success('提报添加成功')
            this.isSuccess = true

          } else {
            this.$message.warning('浏览器不支持导出文件')
            this.dialogData.visible = false
            this.isSuccess = false
          }
          this.$emit('getexportState', this.isSuccess)
          this.isSuccess ? this.formData = {} : this.formData

        }
        //  downloadKolProject
      })

    }
  }
}
</script>

<style scoped>

</style>