<template>


  <el-drawer title="添加提报" :visible="trolleyDrawer" direction="rtl" @close="dialogClose" size="90%">
    <el-row :gutter="24">
      <el-col :span="10" :offset="1">
        <el-card class="box-card">
          <exportDialog :idsProp="trolley"
                        ref="submit" @getexportState="getexportState"
                        @dialogClose="dialogClose"></exportDialog>
        </el-card>
      </el-col>
      <el-col :span="11" :offset="1">
        <el-card class="box-card">
          <el-table :data="trolley" ref="multipleTable">
            <el-table-column align="center" label="序号" type="index" width="50"></el-table-column>
            <el-table-column align="center" label="红人昵称" prop="kol_info.nickname" show-overflow-tooltip>
              <template slot-scope="{ row }">
                <el-link @click="detailPage(row.kol_id)" size="mini" type="primary"
                         v-if="userPermissions.indexOf('kol_info_item') !== -1">
                  <span>{{ row.kol_info.nickname }} </span>
                </el-link>
              </template>
            </el-table-column>
            <el-table-column align="center" label="个人/机构(名称)" prop="kol_info.organ_name"></el-table-column>
            <el-table-column align="center" label="性别" width="50">
              <template slot-scope="{ row }">
                <span>{{ row.kol_info.sex == '1' ? '男' : row.kol_info.sex == '2' ? '女' : '其他' }}</span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="130">
              <template slot-scope="{ row }">
                <el-link @click="delTrolley(row)" icon="el-icon-s-release" size="mini" type="primary"
                         v-if="userPermissions.indexOf('inquiry_delete') !== -1">删除
                </el-link>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <br/>
    <div>
      <el-button style="display: block;margin: 0 auto" type="primary"
                 @click="handleConfirm" v-if="userPermissions.indexOf('kol_resource_item') !== -1">确认并导出
      </el-button>
      <!--      <el-button style="display: block;margin: 0 auto" @click="dialogClose">取 消</el-button>-->
    </div>
  </el-drawer>


</template>

<script>

import { mapGetters } from 'vuex'
import exportDialog from '@/pages/kol/components/Dialog'

export default {
  name: 'Dialog',
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },
  components: {
    exportDialog
  },
  data() {
    return {
      dialogData: false
    }

  },
  props: {
    trolley: {
      type: [Array],
      default() {
        return []
      }
    },
    trolleyDrawer: {
      type: Boolean,
      default: false
    }

  },
  methods: {
    // 处理模态框，关闭事件
    dialogClose() {
      this.$emit('update:trolleyDrawer', false)
      // this.dialogData.visible = false
    },
    delTrolley(row) {
      this.$emit('delTrolley', row)
    },
    getexportState(val) {
      if (val) {
        this.$refs.multipleTable.clearSelection()
        this.dialogVisible = false
        this.trolleyDrawer = false
        this.trolley = []
      }
    },
    handleConfirm() {

      this.$refs.submit.handleConfirm()
    }

  }

}
</script>

<style scoped>

</style>