<template>
    <el-row class="table-search">
        <el-col :span="24">
            <el-form :inline="true" label-position="right" v-model="platformData">
                <el-form-item>
                    <el-input @blur="handleQuery" @clear="handleQuery"
                              class="input-with-select" clearable placeholder="红人昵称" style="width: 100px"
                              v-model="platformData.nickname"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select @change="handleQuery" clearable
                               placeholder="性别" style="width: 100px" v-model="platformData.sex">
                        <el-option label="男" value="1"></el-option>
                        <el-option label="女" value="2"></el-option>
                        <el-option label="其他" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select @change="handleQuery" @clear="handleQuery" clearable placeholder="红人肤质"
                               style="width: 100px" v-model="platformData.skin_type">
                        <el-option label="干性皮肤" value="干性皮肤"></el-option>
                        <el-option label="中性皮肤" value="中性皮肤"></el-option>
                        <el-option label="敏感性皮肤" value="敏感性皮肤"></el-option>
                        <el-option label="混合性皮肤" value="混合性皮肤"></el-option>
                        <el-option label="其他" value="其他"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input @blur="handleQuery" @clear="handleQuery" class="input-with-select"
                              clearable placeholder="个人/机构" style="width: 100px"
                              v-model="platformData.organ_name"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input @blur="handleQuery" @clear="handleQuery" class="input-with-select"
                              clearable placeholder="媒介" style="width: 120px"
                              v-model="platformData.referrer"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input @blur="handleQuery" @clear="handleQuery" class="input-with-select"
                              clearable placeholder="账号标签" style="width: 120px"
                              v-model="platformData.account_label"></el-input>
                </el-form-item>
                <el-form-item v-if="typePlatform==='xiaohongshu'">
                    <el-select @change="handleQuery" clearable placeholder="是否报备"
                               style="width: 120px"
                               v-model="searchCondition.is_report">
                        <el-option label="报备" value="1"></el-option>
                        <el-option label="不报备" value="2"></el-option>

                    </el-select>
                </el-form-item>
                <el-form-item label="粉丝量：">
                    <el-select  placeholder="范围" style="width: 100px"
                               v-model="platformData.fans_total.range">
                        <el-option label="大于" value=">"></el-option>
                        <el-option label="等于" value="="></el-option>
                        <el-option label="小于" value="<"></el-option>
                    </el-select>
                    <el-input @blur="handleQuery" @clear="handleQuery" class="input-with-select" clearable
                              oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                              placeholder="范围值" style="width: 120px"
                              v-model="platformData.fans_total.value"></el-input>
                </el-form-item>
                <el-form-item label="赞藏数：">
                    <el-select  placeholder="范围" style="width: 100px"
                               v-model="platformData.like_total.range">
                        <el-option label="大于" value=">"></el-option>
                        <el-option label="等于" value="="></el-option>
                        <el-option label="小于" value="<"></el-option>
                    </el-select>
                    <el-input @blur="handleQuery" @clear="handleQuery" class="input-with-select"
                              clearable oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                              placeholder="范围值" style="width: 120px"
                              v-model="platformData.like_total.value"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input @blur="handleQuery" @clear="handleQuery" class="input-with-select"
                              clearable placeholder="合作品牌" style="width: 120px"
                              v-model="platformData.co_brand"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select @change="handleQuery" clearable
                               placeholder="配合度" style="width: 120px" v-model="platformData.fit">
                        <el-option label="红榜" value="红榜"></el-option>
                        <el-option label="黑榜" value="黑榜"></el-option>
                        <el-option label="一般" value="一般"></el-option>
                        <el-option label="暂无" value="暂无"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button @click="handleQuery" type="primary">查询</el-button>
                </el-form-item>
            </el-form>
        </el-col>
    </el-row>

</template>

<script>

  export default {
    name: 'TableSearch',
    data() {
      return {}
    },
    props: {
      typePlatform: {
        type: String,
        default: ''
      },
      searchCondition: {
        type: Object,
        default() {
          return {
            nickname: '',
            sex: '',
            organ_name: '',
            referrer: '',
            account_label: '',
            skin_type: '',
            fans_total: {
              range: '=',
              value: ''
            },
            like_total: {
              range: '=',
              value: ''
            },
            co_brand: '',
            fit: ''
          }
        }
      }
    },
    computed: {
      platformData: {
        get() {
          return this.searchCondition
        }
      }
    },
    methods: {
      handleQuery() {
        this.$emit('handleQuery', this.platformData)
      }
    },
    mounted() {
    }
  }
</script>

<style scoped>

</style>